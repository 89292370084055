import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import styled from 'styled-components'
import { t } from '../helpers'

const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContextCreatedAt = Date.now()


class AudioLoader extends React.Component {
  constructor(props) {
    super(props);
    
    this.req = new XMLHttpRequest();
    this.req.open('GET', this.props.path, true);
    this.req.responseType = 'arraybuffer';

    this.req.onload = ()=> {
       // Onload is triggered even on 404
       // so we need to check the status code
       if (this.req.status === 200) {
          console.log("complete");
          this.props.onComplete(this.req.response);
       }
    }

    this.req.onprogress = (oEvent)=> {
      if (oEvent.lengthComputable) {
        var percentComplete = oEvent.loaded / oEvent.total * 100;
        this.props.onProgress(percentComplete);
      } else {
        // Unable to compute progress information since the total size is unknown
      }
    }
    this.req.send();
  }

  componentWillUnmount() {
    this.req.onload = null;
    this.req.onprogress = null;
  }


  render() {
    return null;
  }
}

export class MultiChannelAudioPlayer extends React.Component {

  constructor(props) {

    console.log("tracks", props.tracks);

    audioContextCreatedAt = Date.now()
    super(props);
    this.state = {
      channelsOn: props.activeTracks ? props.activeTracks : props.tracks.map(()=>true),
      controlStatus: props.playbackControlStatus ? props.playbackControlStatus : "loading",
      loadingStatus: "",
      avgLoaded: 0,
      playbackPosition: 0,
      playbackStartedAt: 0,
      bufferDuration: 0,
    }
    this.handlePlay = this.handlePlay.bind(this);
    this.handlePause = this.handlePause.bind(this);
    this.handleRewind = this.handleRewind.bind(this);
    this.updatePlaybackPosition = this.updatePlaybackPosition.bind(this);
    
    try {
      if(window.reusableAudioContext) {
        this.audioContext = window.reusableAudioContext;
      } else {
        this.audioContext = new AudioContext();      
        window.reusableAudioContext = this.audioContext;
      }
    }
    catch(e) {
      console.log(e);
    }

    if(!this.audioContext) {
      console.log("warning - no audio context");
    }

    
    
    this.audioBuffers = props.tracks.map(()=>null);      
    this.samples = props.tracks.map(()=>null);      
    this.gainNodes = props.tracks.map(()=>
      {return this.audioContext ? this.audioContext.createGain() : null}
    );          

    this.panners = props.tracks.map((track, index)=>{
      //const pannerOptions = { pan: -1 + (2 / this.props.tracks.length) * index };
      const pannerOptions = { pan: 0 };
      if (typeof StereoPannerNode !== 'undefined') {
        return new StereoPannerNode(this.audioContext, pannerOptions);
      }
      return null;
    });

    this.loaded = props.tracks.map(()=>0);      
    this.decoded = props.tracks.map(()=>false);      
    this.calculateLoadingStatus = this.calculateLoadingStatus.bind(this);

    this.loaders = this.props.tracks.map((track, index)=>
      <AudioLoader
          key={index}
          path={track.file}
          onProgress={(loaded)=>{
            if(this._unmounted) return;
            this.loaded[index] = loaded;
            this.calculateLoadingStatus();
          }}
          onComplete={async (arrayBuffer)=>{

            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            if(isSafari) {
              if(!this.audioContext) {
                this.audioContext = new AudioContext();  
              }

              if(this.audioContext)
                this.audioContext.decodeAudioData(arrayBuffer, (audioBuffer)=>{
                  if(this._unmounted) return;
                  console.log("decoded safari");
                  this.audioBuffers[index] = audioBuffer;            
                  this.loaded[index] = 100;
                  this.decoded[index] = true;
                  this.calculateLoadingStatus();
                });  
              else console.log("audioContext not created")
            } else {
              this.audioContext.decodeAudioData(arrayBuffer).then((audioBuffer)=>{
                if(this._unmounted) return;
                console.log("decoded");
                this.audioBuffers[index] = audioBuffer;            
                this.loaded[index] = 100;
                this.decoded[index] = true;
                this.calculateLoadingStatus();
              });
            }
            
            
          }}
      />
    );

    console.log("loaders", this.loaders);
  }

  componentDidMount() {
    this._unmounted = false;
  }

  componentWillUnmount() {
    this.handlePause()
    this._unmounted = true;
  } 

  calculateLoadingStatus() {
    let total = 0;
    this.loaded.forEach((l)=>total+=Number(l));
    let avg = (total / this.loaded.length).toFixed(1);
    this.setState({avgLoaded: avg});

    let allDecoded = true;
    this.decoded.forEach(d=>{
      if(!d) allDecoded = false;
    });

    if(avg < 100) {
      this.updateLoadingStatus(t(this.props.translations, "loading", this.props.locale) + avg + "%");     
    } else {
      this.updateLoadingStatus(t(this.props.translations, "preparing", this.props.locale));     
    }
    
    if(avg == 100 && allDecoded) {
      this.updatePlaybackControlStatus("ready");  
      this.updateLoadingStatus("");     

      this.setState({bufferDuration: this.audioBuffers[0].duration});
    }
  }

  updateLoadingStatus(status) {
    this.setState({loadingStatus: status});
    if(this.props.updateLoadingStatus) {
      this.props.updateLoadingStatus(status);
    }
  }

  updatePlaybackControlStatus(status) {
    if(this.props.updatePlaybackControlStatus) {
      this.props.updatePlaybackControlStatus(status);
    }

    if(this.state.controlStatus !== status) {
      this.setState({controlStatus: status});
      if(status === "playing") {
          this.handlePlay();
      }
      if(status === "ready") {
        this.handleRewind();
      }
      if(status === "paused") {
        this.handlePause();
      }
    }
  }
  
  componentDidUpdate() {
    if(!this.props.controls) {
      if(this.state.controlStatus !== this.props.playbackControlStatus) {
        this.setState({controlStatus: this.props.playbackControlStatus})
        if(this.props.playbackControlStatus === "playing") {
          this.handlePlay();
        }
        if(this.props.playbackControlStatus === "ready") {
          this.handleRewind();
        }
        if(this.props.playbackControlStatus === "paused") {
          this.handlePause();
        }
      }
    }

    if(this.props.activeTracks) {
      for(let i = 0; i < this.props.activeTracks.length; i++) {
        if(this.gainNodes[i]) {
          this.gainNodes[i].gain.value = this.props.activeTracks[i] ? 1.0 : 0.0;
        }
      }
    }
  }

  playSample(index, start, offset=0) {
    
    const sampleSource = this.audioContext.createBufferSource();
    
    sampleSource.buffer = this.audioBuffers[index];

    if(this.panners[index]) {
      sampleSource.connect(this.gainNodes[index]).connect(this.panners[index]).connect(this.audioContext.destination)  
    } else {
      console.log("sampleSource", sampleSource);
      console.log("gain node", this.gainNodes[index]);
      console.log("audioContext", this.audioContext);
      sampleSource.connect(this.gainNodes[index]);
      this.gainNodes[index].connect(this.audioContext.destination);
    }

    sampleSource.start(start, offset);

    // console.log(sampleSource);

    if(index == 0) {
      sampleSource.onended = () => {
        console.log("ended", this.state.playbackPosition, this.state.bufferDuration);
        // if stopping because the files ends
        if (this.state.playbackPosition >= this.state.bufferDuration - 1.5) {
          console.log("setting to ready");
          this.updatePlaybackControlStatus("ready");
        }
      }
    }
    
    this.samples[index] = sampleSource; // save for later stopping and manipulation

  }

  handlePlay() {
    if(this.audioContext.state === 'suspended') {
        this.audioContext.resume();
    }
    let startTime = this.audioContext.currentTime;
    this.setState({
      playbackPosition: this.state.playbackPosition,
      playbackStartedAt: startTime - this.state.playbackPosition
    });
    console.log("starting to play at " + startTime);
    // console.log("playbackPosition " + this.state.playbackPosition);
    if(this.props.updateSequenceStartedAt)
      this.props.updateSequenceStartedAt(audioContextCreatedAt + (startTime - this.state.playbackPosition)*1000);
    
    this.audioBuffers.forEach((buffer, index)=>{
      this.playSample(index, startTime, this.state.playbackPosition); 
    })

    this.playbackPositionInterval = setInterval(this.updatePlaybackPosition, 1000);
  }

  handlePause() {
    this.samples.forEach((sample)=>{
      if(sample) {
        sample.stop();   
      }
    })
    this.updatePlaybackPosition();
    clearInterval(this.playbackPositionInterval);
  }

  updatePlaybackPosition() {
    if(this.audioContext) {
      this.setState({playbackPosition: this.audioContext.currentTime - this.state.playbackStartedAt});   
    }
  }

  handleRewind() {
    this.samples.forEach((sample)=>{
      if(sample) {
        sample.stop();   
      }
    })
    this.setState({playbackPosition: 0});
    clearInterval(this.playbackPositionInterval);
  }

  doSkip = (offset) => {
    let backToPlay = false;
    if(this.state.controlStatus == "playing") {
      this.updatePlaybackControlStatus("paused");
      backToPlay = true;
    }
    let newPosition = this.state.playbackPosition + offset;
    if(newPosition < 0) newPosition = 0
    this.setState({playbackPosition: newPosition}, ()=>{
      if(backToPlay) {
        console.log("resuming play");
        this.updatePlaybackControlStatus("playing");
      }
    });
  }

  formatTime(total_seconds) {
    let s = total_seconds.toFixed(0);
    let min = Math.floor(s / 60);
    let sec = s - min * 60;
    if(sec < 10) sec = "0" + sec;
    return min + ":" + sec;
  }

  render() {

    const controlable = this.state.controlStatus != "loading";
    
    return (
      <ButtonContainer>
        {this.loaders}
        
        {this.props.controls && controlable &&

          [
          <Time>{this.formatTime(this.state.playbackPosition)} / {this.formatTime(this.state.bufferDuration)}</Time>,

          (this.state.controlStatus != "playing" && this.state.controlStatus != "paused") &&          
          <BigButton big onClick={()=>this.updatePlaybackControlStatus("playing")}>
            {t(this.props.translations, "start_listening", this.props.locale)}
          </BigButton>
          ,

          <PlaybackControlsRow> {[

          (this.state.controlStatus == "paused" || this.state.controlStatus == "playing") &&
          <Button onClick={()=>this.doSkip(-10)}>
            <div style={{textAlign: "left"}}>{t(this.props.translations, "back", this.props.locale)}</div>
          </Button>,
          
          (this.state.controlStatus != "paused" && this.state.controlStatus != "ready") &&
          <Button onClick={()=>this.updatePlaybackControlStatus("paused")}>
            <PlaybackIcon src="/images/pause.svg"/>
            <span>{t(this.props.translations, "pause", this.props.locale)}</span>
          </Button>
          ,
        
          (this.state.controlStatus != "playing" && this.state.controlStatus == "paused") &&          
          <Button onClick={()=>this.updatePlaybackControlStatus("playing")}>
            <PlaybackIcon src="/images/play.svg"/>
            <span>{t(this.props.translations, "continue", this.props.locale)}</span>
          </Button>
          ,
        
          this.state.controlStatus != "ready" && 
          <Button onClick={()=>this.updatePlaybackControlStatus("ready")}>
            <PlaybackIcon src="/images/reset.svg"/>
            <span>{t(this.props.translations, "reset", this.props.locale)}</span>
          </Button>
          ,

          (this.state.controlStatus == "paused" || this.state.controlStatus == "playing") &&
          <Button onClick={()=>this.doSkip(+10)}>
            <div style={{textAlign: "left"}}>{t(this.props.translations, "skip", this.props.locale)}</div>
          </Button>

          ]}

          </PlaybackControlsRow>

        ]}
        
        {this.props.controls && <p>{this.state.loadingStatus}</p>}
      </ButtonContainer>
    );
  }

}

const Time = styled.p`
  font-family: light;
  font-size: 16px;
  text-align: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const BigButton = styled.span`
  user-select: none;
  font-size: 38px;
  border: 1px solid black;
  padding: 10px 30px 10px 30px;
  margin-bottom: 10px;
  display: inline-block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  :hover {cursor: pointer};
  text-transform: uppercase;
  font-family: thin;
`

const PlaybackControlsRow = styled.div`
  display: flex;
  flex-direction: row;
`

const Button = styled.div`
  user-select: none;
  font-size: 16px;
  font-family: thin;
  text-transform: uppercase;
  height: 4em;
  word-wrap: break-word;
  border: ${props => props.active ? "4px" : "1px"} solid black;
  padding: 10px 25px 10px 25px;
  margin-bottom: 10px;
  display: inline-block;
  box-sizing: border-box;
  margin-right: 5px;
  width: 24%;
  :hover {cursor: pointer};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const PlaybackIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: 0px;
`