import React, { Component } from 'react';
import styled from 'styled-components'

import { t } from '../helpers'

import { MultiChannelAudioPlayer } from './MultiChannelAudioPlayer';
import ExitButton from './ExitButton';

import axios from 'axios';

import { apiUrl } from '../helpers';



class FancyChannelSelect extends Component {
 
  constructor(props) {
    super(props);

    this.colors = ["#9FE0EF", "#4BFF6A", "#FF4219"];
    this.headphonesImages = [
      "headphones_blue.svg",
      "headphones_green.svg",
      "headphones_red.svg"
    ]
    this.getActiveChannelIndex = this.getActiveChannelIndex.bind(this);
  }

  getActiveChannelIndex() {
    if(this.props.activeTracks[0]) return 0;
    if(this.props.activeTracks[1]) return 1;
    return 2;
  }

  render() {
    const trackButtons = this.props.activeTracks.map((value, index)=>{
      return <ChannelSelect 
        active={value}
        activeColor={this.colors[this.getActiveChannelIndex()]} 
        playing={this.props.playbackControlStatus == "playing"}
        onClick={()=>{this.props.toggleTrack(index)}}>
        {index + 1}
      </ChannelSelect>
    });

    return([
      <FancyContainer>
        <HeadphoneImage src={this.props.playbackControlStatus == "playing" ? 
          "/images/" + this.headphonesImages[this.getActiveChannelIndex()]
          : "/images/headphones.svg" }/>
        <SelectorContainer>
          <ChannelLabel>{t(this.props.translations, "channel", this.props.locale)}</ChannelLabel>
          <SwitchContainer>
            {trackButtons}
          </SwitchContainer>
        </SelectorContainer>
      </FancyContainer>,
      <ChannelExplain
        dangerouslySetInnerHTML={{__html: 
          t(this.props.translations, "channel_explanation", this.props.locale)}}
      />      
      ]
    )
  }
}

const FancyContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 150px;
`

const HeadphoneImage = styled.img`
  
  box-sizing: border-box;
  width: 50%;
  flex: 0.5;
  display: flex;

  @media only screen and (max-width: 768px) {
    padding: 10px 10px 10px 10px;
  }
`

const SelectorContainer = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SwitchContainer = styled.div`
  border: 1px solid black;
  border-radius: 15px;
  margin-top: 5px;
  padding: 1px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  max-width: 200px;

  @media only screen and (max-width: 768px) {
    width: 75%;
  }
`

const ChannelSelect = styled.span`

  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 20px;
  border-radius: 15px;
  display: inline-block;
  border: ${props => props.active && !props.playing ? "1px solid black" : "none"};
  color: ${props => props.active && props.playing ? "white" : "black"};
  background-color: ${props => props.active && props.playing ? props.activeColor : "white"};
  font-family: ${props => props.player && props.active ? "regular" : "thin"};
  padding: 3px;
  box-sizing: border-box;
  :hover {cursor: pointer};
  text-align: center;
`

const ChannelLabel = styled.div`
  font-family: thin;
  width: 100%;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
`

const ChannelExplain = styled.div`
  margin-top: 15px;
  font-family: italic;
  font-size: 12px;
  width: 100%;
`



class PlacePage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      playbackControlStatus: "loading", // ready - playing - paused
      tracks: null
    }
 
    this.updatePlaybackControlStatus = this.updatePlaybackControlStatus.bind(this);
    this.toggleTrack = this.toggleTrack.bind(this);
    this.updatePlace = this.updatePlace.bind(this);
  }

  componentDidUpdate(prevProps) {
    console.log("componentDidUpdate");
    console.log(prevProps);
    console.log(this.props);
    if(!this.props.place || prevProps.place._id != this.props.place._id) {
      this.updatePlace();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.loadTimeout);
  }

  componentDidMount() {
    this.updatePlace();
  }

  async updatePlace() {

    if(this.loadTimeout)
      clearTimeout(this.loadTimeout);

    this.setState({
      playbackControlStatus: "loading", // ready - playing - paused
      tracks: null
    });

    console.log("update place");
    let response = await axios.get(apiUrl + "/place/"+this.props.place._id, {
        params: {
          $embed: JSON.stringify(["image", "audio1", "audio2", "audio3"])
        }
      }  
    );
    console.log(response);
    let tracks = [];
    let activeTracks = [];
    if(response.data.audio1) {
      tracks.push({file: response.data.audio1.url});
      //activeTracks.push(true);

    }
    if(response.data.audio2) {
      tracks.push({file: response.data.audio2.url});
      //activeTracks.push(false);
    }
    if(response.data.audio3) {
      tracks.push({file: response.data.audio3.url});
      //activeTracks.push(false);
    }
    console.log(tracks);
    console.log(response.data);

    this.setState({
      place: response.data,
      //tracks: tracks,
      //activeTracks: activeTracks
    }, ()=>console.log("place", this.state.place));

    this.loadTimeout = setTimeout(async ()=>{

      this.setState({
        //place: response.data,
        tracks: tracks,
        //activeTracks: activeTracks
      });

      await axios.post(apiUrl + "/log_entry", 
        {
          event: "place loaded " + this.props.place["name_" + this.props.locale],
          userId: this.props.userId
        });

      }, 2000);
  }

  updatePlaybackControlStatus(playbackControlStatus) {
    this.setState({playbackControlStatus})
  }

  toggleTrack(index) {
    let activeTracks = this.props.activeTracks;
    activeTracks.forEach((e, i)=>activeTracks[i] = false);
    activeTracks[index] = true;
    this.props.setActiveTracks(activeTracks);
  }

  render () {
    
    return <div>
      <PlacePageBackground onClick={this.props.exitPlace}/>
      <PlacePageContainer>

      <ExitButton
        onClick={this.props.exitPlace}
        translations={this.props.translations}
        locale={this.props.locale}
        textKey="map"
      />

        <ScrollContent>

        <h2>{this.state.place ? this.state.place["name_" + this.props.locale] : ""}</h2>

        <PlaceImageContainer>
        {this.state.place && this.state.place.image && <PlaceImage src={this.state.place.image.url} alt=""/>}
        <PrevPlaceButton onClick={this.props.prevPlace}>{"<"}</PrevPlaceButton>
        <NextPlaceButton onClick={this.props.nextPlace}>{">"}</NextPlaceButton>
        </PlaceImageContainer>

        <PlaceDetails>

          <Description dangerouslySetInnerHTML={{__html:
            this.state.place ? this.state.place["description_" + this.props.locale] : t(this.props.translations, "loading", this.props.locale)}}/>

          {this.state.tracks && <MultiChannelAudioPlayer 
            playbackControlStatus={this.state.playbackControlStatus}
            updatePlaybackControlStatus={this.updatePlaybackControlStatus}
            tracks={this.state.tracks}
            activeTracks={this.props.activeTracks}
            controls
            translations={this.props.translations}
            locale={this.props.locale}
          />}

          {this.state.playbackControlStatus != "loading" && this.props.activeTracks.length > 1 && [
            <FancyChannelSelect 
              activeTracks={this.props.activeTracks}
              toggleTrack={this.toggleTrack}
              translations={this.props.translations}
              locale={this.props.locale}
              playbackControlStatus={this.state.playbackControlStatus}
            />
          ]}

        </PlaceDetails>

        </ScrollContent>
        
      
      </PlacePageContainer>
      
      
      
    </div>
  }
}

export default PlacePage;

const PlacePageBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;  
`

const PlacePageContainer = styled.div`
  position: absolute;
  top: 20px;
  @media only screen and (max-width: 768px) {
    top: 0px;
  }

  left: 20%;
  box-sizing: border-box;
  width: 60%;

  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  padding: 0px;
  background-color: white;

  h2 {
    font-family: thin;
    font-size: 29px;
    text-transform: uppercase;
    margin-top: 90px;
    margin-left: 20px;
    margin-bottom: 10px;
    font-weight: 100;
  }
  
  @media only screen and (max-width: 768px) {
    left: 0;
    width: 100%;
  }
`

const ScrollContent = styled.div`
  overflow-y: scroll;
  height: 100vh;
  box-sizing: border-box;
`

const PlaceDetails = styled.div`
  padding: 0px 20px 20px 20px;
`

const Description = styled.p`
  margin-bottom: 20px;
`

const PlaceImageContainer = styled.div`
  width: 100%;
  min-height: 80px;
  position: relative;
`

const PlaceImage = styled.img`
  width: 100%;
`

const PrevPlaceButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  font-family: light;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 10px;
  background-color: white;
  white-space: snowrap;
  z-index: 10;
  color: black;
  :hover {cursor: pointer;}
`

const NextPlaceButton = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  font-family: light;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 10px;
  background-color: white;
  white-space: snowrap;
  z-index: 10;
  color: black;
  :hover {cursor: pointer;}
`
