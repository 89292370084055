import React, { Component } from 'react';

import MapPage from './MapPage.js'
import PlacePage from './PlacePage.js'
import Menu from './Menu.js'

import axios from 'axios';
import { apiUrl } from '../helpers'
import { t } from '../helpers'

import styled from 'styled-components'
import { createGlobalStyle } from "styled-components";

const uuidv1 = require('uuid/v1');

class BaseContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
      currentPlace: null,
      activeTracks: [true, false, false],
      placeOpen: false,
      menuOpen: false,
      translations: [],
      locale: "en",
      userId: uuidv1()
    }

    this.nextPlace = this.nextPlace.bind(this);
    this.prevPlace = this.prevPlace.bind(this);
    this.setActiveTracks = this.setActiveTracks.bind(this);
  }

  setActiveTracks = (activeTracks) => {
    this.setState({activeTracks: activeTracks});
  }

  async componentDidMount() {

    let response = await axios.get(apiUrl + "/place");

    response.data.docs.sort((a,b)=>a.order - b.order);

    this.setState({places: response.data.docs});

    // todo: check if there is a place that should be open at the beginning
    console.log(response.data.docs);
    let openPlaces = response.data.docs.filter(p=>p.default_open);
    console.log(openPlaces);
    if(openPlaces.length > 0) {
      this.setState({
        currentPlace: openPlaces[0],
        placeOpen: true
      })
    }


    response = await axios.get(apiUrl + "/translation");
    this.setState({translations: response.data.docs});

    await axios.post(apiUrl + "/log_entry", {
      event: "site loaded", 
      userId: this.state.userId
    });
  }

  setLocale = (l)=> {
    this.setState({locale: l})
  }

  findCurrentPlaceIndex = ()=> {
    for(let i = 0; i < this.state.places.length; i++) {
      if(this.state.places[i]._id == this.state.currentPlace._id) {
        return i;
      }
    }
  }

  nextPlace = ()=> {
    let currentIndex = this.findCurrentPlaceIndex();
    console.log(currentIndex);
    if(currentIndex + 1 < this.state.places.length) {
      this.setState({
        currentPlace: this.state.places[currentIndex + 1]
      })
      console.log(this.state.places[currentIndex + 1]);
    } else {
      this.setState({
        currentPlace: this.state.places[0]
      })
    }
  }

  prevPlace = ()=> {
    let currentIndex = this.findCurrentPlaceIndex();
    console.log(currentIndex);
    if(currentIndex > 0) {
      this.setState({
        currentPlace: this.state.places[currentIndex - 1]
      })
    } else {
      this.setState({
        currentPlace: this.state.places[this.state.places.length - 1]
      })
    }
  }


  render() {
    return (
      [<GlobalStyles key="globalstyles" />,
      <div id="main" key="main">
        {!this.state.menuOpen && this.state.placeOpen &&
          <TopButtonLeft onClick={()=>{this.setState({menuOpen: true});}}>
            {t(this.state.translations, "info", this.state.locale)}
          </TopButtonLeft>
        }

        {!this.state.placeOpen &&
          <TopButtonRight onClick={()=>{this.setState({placeOpen: true});}}>
            {t(this.state.translations, "exit_map", this.state.locale)}
          </TopButtonRight>
        }

        {/*<MainHeadline>{this.state.translations.length ? 
              t(this.state.translations, "main_headline", this.state.locale) : ""}</MainHeadline>*/}

        {this.state.menuOpen && 
          <Menu 
            close={()=>{ this.setState({menuOpen: false}); }}
            translations={this.state.translations}
            locale={this.state.locale}
            setLocale={this.setLocale}
          />
        }

        <MapPage
          visible={!this.state.currentPlace} 
          places={this.state.places}
          openPlace={(p)=>{
            this.setState({
              placeOpen: true,
              currentPlace: p
            })}
          }
          currentPlace={this.state.currentPlace}
          locale={this.state.locale}
        />
        {this.state.placeOpen && this.state.currentPlace && 
          <PlacePage 
            place={this.state.currentPlace}
            activeTracks={this.state.activeTracks}
            setActiveTracks={this.setActiveTracks}
            exitPlace={()=>{
              this.setState({placeOpen: false})
            }}
            locale={this.state.locale}
            translations={this.state.translations}
            userId={this.state.userId}
            nextPlace={this.nextPlace}
            prevPlace={this.prevPlace}
          />
        }
      </div>]
    );
  }
}

export default BaseContainer;

const MenuButton = styled.div`
  position: absolute;
  z-index: 100;
  height: 30px;
  left: 25%;
  top: 20px;
  :hover {cursor: pointer}; 
`

const MainHeadline = styled.h1`
  position: absolute;
  z-index: 100;
  left: 30%;
  top: 16px;
  margin: 0;
  font-family: thin;
  font-size: 31px;
  text-transform: uppercase;  
  font-weight: 100;
`

const TopButtonLeft = styled.div`
  position: absolute;
  top: 50px;
  @media only screen and (max-width: 768px) {
    top: 30px;
  }
  left: 20%;
  margin-left: 20px;
  font-family: light;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 10px;
  background-color: white;
  white-space: snowrap;
  z-index: 100;
  color: black;
  :hover {cursor: pointer;}
  @media only screen and (max-width: 768px) {
    left: 0%;
  }
`

const TopButtonRight = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  font-family: light;
  text-transform: uppercase;
  border: 1px solid black;
  padding: 10px;
  background-color: white;
  white-space: snowrap;
  z-index: 100;
  color: black;
  :hover {cursor: pointer;}
`

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: thin;
    src:  url('/fonts/thin.ttf');
    /*[unicode-range: <urange>#;]?
    [font-variant: <font-variant>;]?
    [font-feature-settings: normal|<feature-tag-value>#;]?
    [font-stretch: <font-stretch>;]?
    [font-weight: <weight>];
    [font-style: <style>];*/
  }

  @font-face {
    font-family: light;
    src:  url('/fonts/light.ttf');
    /*[unicode-range: <urange>#;]?
    [font-variant: <font-variant>;]?
    [font-feature-settings: normal|<feature-tag-value>#;]?
    [font-stretch: <font-stretch>;]?
    [font-weight: <weight>];
    [font-style: <style>];*/
  }

  @font-face {
    font-family: regular;
    src:  url('/fonts/regular.ttf');
    /*[unicode-range: <urange>#;]?
    [font-variant: <font-variant>;]?
    [font-feature-settings: normal|<feature-tag-value>#;]?
    [font-stretch: <font-stretch>;]?
    [font-weight: <weight>];
    [font-style: <style>];*/
  }

  @font-face {
    font-family: italic;
    src:  url('/fonts/italic.ttf');
    /*[unicode-range: <urange>#;]?
    [font-variant: <font-variant>;]?
    [font-feature-settings: normal|<feature-tag-value>#;]?
    [font-stretch: <font-stretch>;]?
    [font-weight: <weight>];
    [font-style: <style>];*/
  }


  html, body, #root, #main {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  body {
    font-family: regular, sans-serif;
  }
`
