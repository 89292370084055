import React, { Component } from 'react';

import styled from 'styled-components'
import axios from 'axios';
import { apiUrl, getConfig } from '../helpers';


const mapStyles = require('./GoogleMapStyles.json')
const google = window.google;

class MapPage extends Component {

  constructor(props) {
    super(props);
    this.mapContainerRef = React.createRef();
    this.markers = [];
    this.userMarker = null;

    this.firstLoad = true;

    /*this.resizeMap = ()=>{
      if(this.map)
        google.maps.event.trigger(this.map,'resize');
    };*/
  } 

  componentDidMount() {
    this.map = new google.maps.Map(this.mapContainerRef.current, {
      zoom: 1,
      center: {lat: 0, lng: 0},
      streetViewControl: false, 
      fullscreenControl: false, 
      mapTypeControl: false,
      styles: mapStyles // change default map styles
    });

    //window.addEventListener("resize", this.resizeMap.bind(this));
  } 

  componentWillUnmount() {
    //window.removeEventListener("resize", this.resizeMap.bind(this));
  }

  async componentDidUpdate(prevProps) {
    if(!this.markers.length && this.props.places || this.props.locale != prevProps.locale 
      || this.props.currentPlace != prevProps.currentPlace) {
      
      var latlngbounds = new window.google.maps.LatLngBounds();

      this.markers.forEach((m)=>{
        m.setMap(null);
      })
      this.markers = [];

      this.props.places.forEach((p, index)=>{      

        let placePosition = {lat: p.lat, lng: p.lon};

        let icon = {
          url: "/images/marker.png", // url
          scaledSize: {height: 50, width: 50}, // scaled size
          origin: {x:0, y:0}, // origin
          anchor: {x:25, y:50}, // anchor
          labelOrigin: new google.maps.Point(25 + (p.labelOffsetX ? p.labelOffsetX : 0), 60 + (p.labelOffsetY ? p.labelOffsetY : 0))
        };

        console.log(p.labelOffsetX, p.labelOffsetY);
      
        // add markers for user
        let marker = new google.maps.Marker({
          position: placePosition,
          icon: icon,
          label: {
            color: "#000",
            fontFamily: (!this.props.currentPlace || p._id != this.props.currentPlace._id) ? "thin" : "regular",
            fontSize: "16px",
            text: (this.props.currentPlace && p._id == this.props.currentPlace._id ? "" : "") + (p["label_" + this.props.locale] ? p["label_" + this.props.locale].toUpperCase() : ""),
          },
          map: this.map,
        })
          
        marker.addListener('click', ()=> {
          this.props.openPlace(p);
        });

        this.markers.push(marker)
        latlngbounds.extend(placePosition);

      });
      if(this.firstLoad) {
        this.map.fitBounds(latlngbounds);

        /*let response = await axios.get(apiUrl + "/config");
        let zoom = getConfig(response.data, "base_zoom")
        console.log(zoom);
        if(zoom) {
          this.map.setZoom(zoom);  
        } */ 
        this.firstLoad = false;
      }
    }
  }

  render() {
    return (
      <MapPageContainer visible={this.props.visible}>
        <MapContainer ref={this.mapContainerRef} />
        <LocateButton src="/images/locate.png" 
          onClick={()=>{
            // Try HTML5 geolocation
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition((position)=> {
                var pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                };

                console.log("setting userPosition");
                this.map.panTo(pos);

                if(this.userMarker) this.userMarker.setMap(null);
                this.userMarker = new google.maps.Marker({
                    map: this.map,
                    position: pos,
                    icon: {
                        path: google.maps.SymbolPath.CIRCLE,
                        fillColor: '#000',
                        fillOpacity: 0.6,
                        strokeColor: '#000',
                        strokeOpacity: 0.9,
                        strokeWeight: 1,
                        scale: 6
                    }

                });

              }, ()=> {
                alert("couldn't get location");
              }, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
              });
            } else {
              alert("browser doesn't support location");
            }
          }}
        />
      </MapPageContainer>
    )
  }
}

export default MapPage;

const MapPageContainer = styled.div`
  /*visibility: ${props => props.visible != false ? "visible" : "hidden"}*/
  width: 100%; 
  height: 100%;
`

const MapContainer = styled.div`
  display: flex;
  width: 100%; 
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
`

const LocateButton = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  right: 10px;
  bottom: 120px;
  padding: 5px;
  box-shadow: 2px 2px #ddd;
  border-radius: 2px;
  background-color: white;
  font-weight: bold;
  :hover {cursor: pointer};
`
